import axios from '@/http/axios'
import {createCancelTokenHandler, makeQuery} from "../../axios/utils";

const cancelTokenHandlerObject = {
  getDocuments: createCancelTokenHandler('getDocuments'),
  getDocumentTransactions: createCancelTokenHandler('getDocumentTransactions'),
}

export function getDocuments (type = '', page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  let $endPoint = ''
  switch (type) {
  case 'receive':
    $endPoint = '/receive'
    break

  case 'payment':
    $endPoint = '/payment'
    break

  case 'cost':
    $endPoint = '/cost'
    break

  case 'internal-funds-transfer':
    $endPoint = '/internal-fund'
    break
  }

  return axios({
    url: `v1/admin/accountancy/documents${$endPoint}${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getDocuments'].handleRequestCancellation().token
  })
}

export function getDocument (id) {

  return axios({
    url: `v1/admin/accountancy/documents/${id}`,
    method: 'get'
  })
}

export function deleteDocument (id, type) {
  let $endPoint = ''
  switch (type) {
    case 'receive':
      $endPoint = '/receive'
      break

    case 'payment':
      $endPoint = '/payment'
      break

    case 'cost':
      $endPoint = '/cost'
      break

    case 'internal-funds-transfer':
      $endPoint = '/internal-fund'
      break
  }
  return axios({
    url: `v1/admin/accountancy/documents${$endPoint}/${id}`,
    method: 'delete'
  })
}

export function getDocumentTransactions (id, page = 1, filters = {}, sorts = []) {
  let queries = makeQuery(page, filters, sorts)

  if (queries.length > 0) queries = `?${  queries.join('&')}`

  return axios({
    url: `v1/admin/accountancy/documents/${id}/transactions${queries}`,
    method: 'get',
    // setting a cancel token and canceling the previous request of this type
    cancelToken: cancelTokenHandlerObject['getDocumentTransactions'].handleRequestCancellation().token
  })
}
